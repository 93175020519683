
$mdc-typography-styles-headline1: (
  font-family: unquote("Arial, Helvetica, sans-serif")
);
$mdc-typography-styles-headline2: (
  font-family: unquote("Arial, Helvetica, sans-serif"),
  font-size: 5.25rem
);
/* $typography
 ------------------------------------------*/
a {
  text-decoration: none;
  color: inherit;

  @include hover-focus {
    cursor: pointer;
    text-decoration: none;
    outline: 0;
  }
}

button {
  outline: 0;
}

figure {
  margin: 0;
}

hr {
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  border-top: 1px solid mat-color($foreground, divider);
}

strong,
b {
  font-weight: 700;
}

.strikethrough {
  text-decoration: line-through;
}
