/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

  // header css starts here
  .userheaderStyle {
    .width11 {
      width: 9% !important;
    }

    .logo {
      height: 60px !important;
    }

    flex-wrap: wrap;
    height: fit-content !important;

    .branding {
      min-width: 40%;
    }

    .tableData {
      min-width: 40%;
    }



    .cart,
    .menu {
      width: 10% !important;
    }

    .name {
      min-width: 30% !important;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: end;
    }
  }

  .headSection {
    padding: 15px;
  }

  .searchSection {
    mat-expansion-panel {

      mat-expansion-panel-header {
        padding: 0px 20px !important;
      }

      .mat-expansion-panel-content {
        padding: 0px 20px;
      }
    }
  }

  // header css ends here
  // listing css start here

  .listing {
    flex-direction: column;

    .userPaginator .mat-paginator-container {
      padding: 0 !important;
    }


    .menuButton {
      width: 24px !important;
    }



    .cardLayout .imgSection {
      width: 20vw;

      img {
        height: 100px;
      }
    }


    .dataSection .content {
      mat-card-title {
        font-size: 0.8em !important;
        margin-top: 7px !important;
        margin-bottom: 13px !important;
      }

      .textContent {
        padding-right: 10px !important;
      }
    }

    .shortData {
      font-size: 0.7em !important;
      height: 26.4px !important;
    }

    .moreData {
      font-size: 0.7em !important;
    }

    .filtersSection {
      height: auto !important;
      max-width: 100% !important;
      padding: 0px !important;
    }

    .BookdataWrapper {
      width: auto !important;
      padding: 10px 20px !important;
    }

    .addButton {
      position: absolute;
      bottom: 5px;
      right: 5px;

      .primaryButton {
        margin-top: 0px !important;
        font-size: 14px !important;
        padding: 0 10px !important;
        line-height: 27px !important;

        .mat-icon {
          height: 20px !important;
          width: 20px !important;
          font-size: 18px !important;
        }
      }
    }

    .dataSection {

      .content mat-card-subtitle {
        margin-bottom: 2px !important;
        font-size: 0.6em !important;
        max-width: 100% !important;
      }
    }



  }

  .authorshort {
    height: auto !important;
  }

  .moreData {
    font-size: 0.7em !important;
  }

  // for cart screen
  .cartMain {
    .reserveCheckbox {
      margin-bottom: 12px !important;
    }

    padding: 20px 0px !important;

    .flexCenter>button {
      padding: 0px 11px !important;
    }

    .list-content {
      min-height: auto !important;
      flex-direction: column-reverse;


      .cardLayout .imgSection {
        width: 20vw;

        img {
          height: 100px;
        }
      }


      .dataSection .content {
        mat-card-title {
          font-size: 0.8em !important;
          margin-top: 7px !important;
          margin-bottom: 13px !important;
        }

        mat-card-subtitle {
          margin-bottom: 2px !important;
          font-size: 0.6em !important;
          max-width: 100% !important;
        }
      }

      .shortData {
        font-size: 0.7em !important;
        height: 26.4px !important;
      }



      .BookdataWrapper {
        width: auto !important;
        padding: 10px 8px !important;
      }



      .detailSection {
        margin: 0px 7px !important;
      }

      .closeButton {
        width: 45px !important;
        height: 45px !important;

      }
    }

    .mainHeading {
      font-size: 20px !important;
      padding: 0px 20px;

    }
  }

  .ackSection {
    padding: 20px 10px !important;
  }

  .tableSection {
    overflow-x: auto;
    max-height: 50vh;


    th {
      min-width: 100px;
      padding: 0px 10px !important;
    }
  }

  // cart screen ends here

  .customPopup {
    min-width: 80vw !important;

    min-height: 37vh !important;
    max-height: 60vh !important;

    .heading {
      font-size: 1em !important;

    }

    .mat-card {
      .mat-card-subtitle {
        font-size: 0.7em !important;
      }

      .mat-card-title {
        font-size: 0.7em !important;
      }

      img {
        width: 18% !important;
        padding: 0px !important;
      }
    }
  }

  .userPopup {
    padding-top: 0px;
    min-width: 80vw !important;
    min-height: 26vh !important;

    .popupWrapper {

      background-size: 41% !important;
      background-position-x: -5% !important;

      h1 {
        font-size: 1.2em !important;
      }

      button {
        min-width: 52px !important;
        line-height: 26px !important;
      }

      >div {
        padding: 20px 0px 0px 68px !important;
      }
    }
  }
}



/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 600px) and (max-width: 767px) {

  .authorshort {
    height: auto !important;
  }

  .listing {
    flex-direction: column;


    .userPaginator .mat-paginator-container {
      padding: 0 !important;
    }

    .menuButton {
      width: 24px !important;
    }


    .cardLayout .imgSection {
      width: 20vw;

      img {
        height: 100px;
      }
    }

    .dataSection {


      .content {
        mat-card-title {
          font-size: 1em !important;
          margin-top: 7px !important;
          margin-bottom: 13px !important;
        }

        .textContent {
          padding-right: 10px !important;
        }

      }
    }



    .shortData {
      font-size: 0.7em !important;
      height: 26.4px !important;
    }

    .filtersSection {
      height: auto !important;
      max-width: 100% !important;
      padding: 0px !important;
    }

    .BookdataWrapper {
      width: auto !important;
      padding: 10px 20px !important;
    }

    .addButton {
      position: absolute;
      bottom: 5px;
      right: 5px;

      .primaryButton {
        margin-top: 0px !important;
        font-size: 14px !important;
        padding: 0 10px !important;
        line-height: 27px !important;

        .mat-icon {
          height: 20px !important;
          width: 20px !important;
          font-size: 18px !important;
        }
      }
    }

    .dataSection {

      .content mat-card-subtitle {
        margin-bottom: 2px !important;
        font-size: 0.8em !important;
        max-width: 100% !important;
      }
    }

  }

  .headSection {
    padding: 15px;
  }

  .searchSection {
    mat-expansion-panel {

      mat-expansion-panel-header {
        padding: 0px 20px !important;
      }

      .mat-expansion-panel-content {
        padding: 0px 20px;
      }
    }
  }


  // for cart screen
  .cartMain {
    .reserveCheckbox {
      margin-bottom: 12px !important;
    }

    padding: 20px 0px !important;


    .flexCenter>button {
      padding: 0px 11px !important;
    }

    .list-content {
      min-height: auto !important;
      flex-direction: column-reverse;


      .cardLayout .imgSection {
        width: 20vw;

        img {
          height: 115px;
        }
      }


      .dataSection .content {
        mat-card-title {
          font-size: 1em !important;
          margin-top: 7px !important;
          margin-bottom: 13px !important;
        }

        .textContent {
          padding-right: 10px !important;
        }

        mat-card-subtitle {
          margin-bottom: 2px !important;
          font-size: 0.7em !important;
          max-width: 100% !important;
        }
      }

      .shortData {
        font-size: 0.7em !important;
        height: 26.4px !important;
      }



      .BookdataWrapper {
        width: auto !important;
        padding: 10px 8px !important;
      }



      .detailSection {
        margin: 0px 7px !important;
      }

      .closeButton {
        width: 45px !important;
        height: 45px !important;

      }
    }

    .mainHeading {
      font-size: 20px !important;
      padding: 0px 20px;

    }
  }

  .ackSection {
    padding: 20px 10px !important;
  }

  .tableSection {
    overflow-x: auto;
    max-height: 50vh;

    th {
      min-width: 100px;
      padding: 0px 10px !important;
    }
  }

  // cart screen ends here
  .customPopup {
    min-width: 80vw !important;
    min-height: 45vh !important;
    max-height: 60vh !important;


    .heading {
      font-size: 1em !important;

    }

    .mat-card {
      .mat-card-subtitle {
        font-size: 0.8em !important;
      }

      .mat-card-title {
        font-size: 0.7em !important;
      }

      .textContent {
        padding-right: 10px !important;
      }

      img {
        width: 18% !important;
        padding: 0px !important;
      }
    }
  }

  .userPopup {
    padding-top: 0px;
    min-width: 80vw !important;
    min-height: 26vh !important;

    .popupWrapper {
      background-size: 41% !important;
      background-position-x: -5% !important;

      h1 {
        font-size: 1.2em !important;
      }

      button {
        min-width: 52px !important;
        line-height: 26px !important;
      }

      >div {
        padding: 20px 0px 0px 68px !important;
      }
    }
  }

}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
  // css starts here for responsive header ///

  .flexCenter button {
    margin: 10px 0px !important;
  }

  .cartMain {
    .reserveCheckbox {
      margin-bottom: 12px !important;
    }

    padding: 20px 30px !important;
  }


}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}
