@import "scss/material.variables";
@import "scss/material.themes";
@import "scss/mixins/bi-app/bi-app-ltr";
// Core Variables and Mixins
@import "scss/app.variables";
@import "scss/mixins";
// Core Styles
@import "scss/core";
@import "scss/material";
@import "scss/utilities/utilities";
$baseColor: #F9B800;
$secondry: #414141;

.border {
  border: 1px solid;
}

.datatable-row-even {
  background: #f5f2f0;
}

.ngx-datatable.material.single-selection .datatable-body-row.active {
  color: white !important;
}

.ngx-datatable.material.single-selection .datatable-body .datatable-body-row.active .datatable-body-cell {
  color: white !important;
}

.search-form input {
  border: 1px solid $secondry;
}

.fadded {
  color: #b7b7b7 !important;
}

.secondry {
  color: $secondry;
}

.flexCenter {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

body .mat-card .mat-card-title,
body .mat-card .mat-card-title h2 {
  font-size: 22px;
}

.avatar {
  vertical-align: middle;
  width: 75px;
  height: 75px;
  border-radius: 50%;
}

.ngx-datatable.material .datatable-header .datatable-header-cell {
  font-size: 16px;
}

mat-sidenav.sidebar-panel .navigation .menu-badge {
  display: inline-block;
  height: 18px;
  min-width: 10px;
  line-height: 18px;
  text-align: center;
  border-radius: 18px;
  font-size: 10px;
  font-weight: 700;
  padding: 0 4px;
}

.mat-orange,
.mat-green {
  color: #fff !important;
}

mat-sidenav.sidebar-panel {
  width: 300px;
  background-color: #f8f8f8;
  // background: mat-color($background);
}

mat-sidenav.sidebar-panel .navigation .menu-badge {
  display: inline-block;
  height: 24px;
  min-width: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 30px;
  font-size: 11px;
  font-weight: 700;
  padding: 0 5px;
}

//mat-toolbar.main-header .logo {
// background-image: url(/assets/images/TTIPL.png) !important;
//}
mat-toolbar.main-header .logo {
  // background-image: url('');
  // width: 56px;
  // height: 25px;
  // margin: auto;
  background-repeat: no-repeat;
  // margin-bottom: 17px;
  width: 184px;
  background-size: 138px 69px;
  height: 88px;
  margin-top: 0px;
  background-image: url('../images/Library_bw_logo@1x.png');
}

.mat-typography h2:not(.tinr) {
  margin: 0 0 0px;
}

mat-select span {
  color: #6E6E6E;
}

.mat-card-actions .mat-raised-button {
  margin-left: 16px !important;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12) !important;
}

.mat-card-actions {
  padding-top: 0px !important;
}

.mat-input-element,
.mat-input-element:disabled {
  font-size: 20px !important;
  color: #000;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  font-size: 17px !important;
  color: #626188;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-empty.mat-form-field-label {
  font-size: 17px;
}

mat-file-upload-queue .mat-raised-button:not([class*=mat-elevation-z]) {
  margin-right: 15px !important;
}

form .mat-text-warn {
  margin-bottom: 10px;
  margin-top: -10px;
}

.datatable-body-cell,
ngx-datatable {
  cursor: pointer !important;
}

.fulllwidth mat-form-field {
  width: 100%;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #1c5e92;
}

.mk-margin {
  padding-top: 23px;
}

.file-upload-table {
  margin-top: 10px;
}

.table.file-upload-table th,
table.file-upload-table td {
  padding-top: 5px;
  padding-bottom: 0px;
}

.mat-approved {
  background-color: #70B37C !important;
  color: #fff !important;
}

.mat-rejected {
  background-color: #808080 !important;
  color: #fff !important;
}

.mat-pending {
  background-color: #EC932F !important;
  color: #fff !important;
}

.mat-ongoing {
  background-color: #1c5e92 !important;
  color: #fff !important;
}

.mat-my {
  background-color: #E04D57 !important;
  color: #fff !important;
}

.mat-draft {
  background-color: #6fafa9 !important;
  color: #fff !important;
}

.mat-all {
  background-color: #5290E8 !important;
  color: #fff !important;
}

.alert-danger {
  color: #a94442 !important;
}

.sucess-ttas {
  font-size: 13px;
  text-align: center;
  z-index: 999999 !important;
  position: fixed !important;
  top: auto;
  left: auto;
}

.select-checkbox {
  margin-right: 10px;
  vertical-align: middle;
}

.ng-star-inserted .clname textarea {
  text-align: center;
  margin-bottom: 5px;
  padding: 5px;
  margin: 0 14px 12px;
  margin-top: 7px;
  text-align: left;
}

td.clname[_ngcontent-c9] p[_ngcontent-c9] {
  text-align: left;
  margin-left: 10px !important;
}

.record-actions a {
  font-size: 16px;
}


/* Tooltip container */

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  /* If you want dots under the hoverable text */
  opacity: 1 !important;
}


/* Tooltip text */

.tooltip .tooltiptext {
  visibility: hidden;
  width: 250px;
  top: 10px;
  max-height: 140px;
  overflow-y: auto;
  background-color: #000;
  color: #fff;
  text-align: left;
  padding: 7px;
  border-radius: 6px;
  left: -92px;
  font-size: 13px;
  position: absolute;
  z-index: 99999;
}


/* Show the tooltip text when you mouse over the tooltip container */

.tooltip:hover .tooltiptext {
  visibility: visible;
}


.mat-list-item.open .trigger {
  background-color: mat-color($primary);
  color: #ffffff !important;
}

.mat-list-item.open .sub-menu .mat-list-item.open a {
  // background-color:mat-color($primary);
  color: $secondry;
  font-weight: bold;
}

.mat-list-item:hover .trigger {
  background-color: mat-color($primary) !important;
  color: #ffffff !important;
}

.mat-list-item .sub-menu a:hover {
  background-color: mat-color($primary) !important;
  font-weight: normal !important;
  color: #ffffff !important;
}

.sectionHeading {
  color: mat-color($primary);
  text-align: left;
  font: normal normal bold 25px/35px Helvetica Neue;
  letter-spacing: 0px;
}

.colBtnClass {
  color: mat-color($primary);
}

.sectionSubHeading {
  color: $secondry;
  font-size: 21px;
  margin-top: 8px;
  margin-bottom: 15px;
  font-weight: 500;
  letter-spacing: 0.5px;
}


.ngx-datatable.material.single-selection .datatable-body-row.active,
.ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.material.multi-selection .datatable-body-row.active,
.ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background-color: inherit !important;
  color: black !important;
}

.ngx-datatable.material.single-selection .datatable-body-row.active:hover,
.ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group,
.ngx-datatable.material.multi-selection .datatable-body-row.active:hover,
.ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
  background-color: inherit !important;
  color: black !important;
}

.ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group {
  background-color: inherit !important;
  color: black !important;
}

.ngx-datatable.material.single-selection .datatable-body .datatable-body-row.active .datatable-body-cell {
  color: black !important;
}

.mat-option span.mat-option-text {
  white-space: pre-line;
  line-height: 1.1;
  display: block;
}

.ttas-form .mat-required .mat-form-field-label:after {
  color: #e32;
  content: ' *';
  display: inline;
}

.mat-select-search-inside-mat-option[_nghost-c14] .mat-select-search-input[_ngcontent-c14] {
  height: 2.4em !important;
}

.auto-met-option {
  height: auto !important;
  min-height: 48px;
}

.ng-gauge {
  position: relative;
  width: auto !important;
}

.mat-select-panel {
  min-width: 100% !important;
}

.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell .datatable-body-cell-label {
  width: 100%;
  word-break: break-word !important;
}

.ngx-datatable.vscroll .datatable-body {
  max-height: 70vh !important;
  overflow: hidden auto;
}

.ngxcolwrap {
  white-space: initial !important;
}

.custom-hide-head,
.custom-hide-cell {
  display: none !important;
  width: 0px !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: rgb(66, 134, 244);
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  color: rgb(66, 134, 244);
  background-color: rgb(66, 134, 244);
}

.ngx-datatable .datatable-body {
  height: 500px;
}

.ackDialog .close-button {
  float: right;
  top: -17px;
  right: -16px;
}

.ackDialog .close-button:focus {
  outline: none;
}

.ackDialog .close-icon {
  transition: 1s ease-in-out;
}

.ackDialog .close-icon:hover {
  transform: rotate(180deg);
}

::ng-deep .ackDialog .icon-outside .close-button {
  float: right;
  top: -52px;
  right: -52px;
}

::ng-deep .ackDialog .icon-outside .mat-dialog-container {
  overflow: unset
}

.userheaderStyle .editInput {
  .mat-form-field-infix {
    padding: 0.25em 0 0.45em 0 !important;
  }

  .mat-form-field-flex {
    padding: 0.3em 0.75em 0 0.75em !important;
  }
}

.titleInput {
  .mat-form-field-flex {
    padding: 0 0em 0 0.75em !important;
  }
}

.tableData {
  .label {
    // text-align: left;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    color: $secondry;
  }

  .full-width {
    width: 100%;
  }

  .editInput {
    .mat-form-field-infix {
      display: flex;
      border-top: none !important;
    }

    input {
      color: #474747;
      font-size: 14px !important;
    }

    .mat-select-arrow {
      margin-top: 13px;
    }
  }

  .filterField .editInput {
    .mat-form-field-flex {
      padding: 0.40em .75em 0 .75em !important;
    }
  }

  .fieldSection {
    margin-bottom: 15px;
  }
}

.tableScreen {
  width: 80%;
  display: flex;
  justify-content: flex-end;

  .editInput .mat-form-field-wrapper {
    padding-bottom: 0px !important;
  }
}

.primaryButton {
  background: $baseColor 0% 0% no-repeat padding-box;
  color: $secondry;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #F1F1F1;
  // border-radius: 6px!important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;

  .mat-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.userPopup .primaryButton .mat-button-wrapper {
  justify-content: center !important;
}

.secondryButton {
  background: #a7a7a7 0% 0% no-repeat padding-box;
  color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #F1F1F1;
  // border-radius: 6px!important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;

  .mat-button-wrapper {
    display: flex;
    /* justify-content: center; */
    align-items: center;
  }
}

.borderButton {
  border: 1px solid $secondry  !important;
  color: $secondry  !important;
  border-radius: 6px;
  padding-top: 3px !important;
  padding-bottom: 3px !important;

  .mat-button-wrapper {
    display: flex;
    /* justify-content: center; */
    align-items: center;
  }
}

.buttonSection {
  width: 20vw;
  padding: 10px 15px 50px 25px;
  background: transparent;
  position: fixed;
  bottom: 0;
  right: 17px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .borderButton {
    margin-right: 20px;
    padding-left: 1px;
  }

  .backButtonIcon {
    display: flex;
    height: 22px;
    width: 22px;
    align-items: center;
    font-size: 22px;
  }
}

#wrapper {
  position: relative;
}

.buttonIcons {
  font-size: 15px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.customPopup {
  text-align: center;
  min-width: 475px;
  min-height: 131px;

  mat-dialog-container .mat-dialog-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.userPopup {
  padding-top: 40px;
  min-width: 706px;
  min-height: 291px;

  .mat-dialog-container {
    padding: 0;
    border-radius: 16px;
  }
}

.reserveCheckbox .mat-checkbox-label {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.8px;
}

.fieldBox {
  // border-radius: 20px;
  // display: flex;
  padding: 2px 15px !important;
  align-items: center;
  justify-content: center;
  background: #F6F6F6 0% 0% no-repeat padding-box;
  border: 1px solid #878787 !important;
  border-radius: 6px !important;
  // .mat-checkbox-inner-container{
  // background: green;
  border-radius: 20px;
  // .mat-checkbox-frame{
  //   border: none;
  // }
  // }
  // .mat-checkbox-frame{
  //   border-radius: 20px!important;
  // }
  // .mat-checkbox-background{
  //   background: red;
  //   border-radius: 20px!important;
  //   .mat-checkbox-checkmark-path{
  //     stroke:transparent!important;
  //    }
  //    .mat-checkbox-checkmark{
  //      fill: transparent!important;
  //    }
  // }
  // .mat-checkbox-checked.mat-accent .mat-checkbox-background{
  //   background: green!important;
  //   .mat-checkbox-checkmark-path{
  //    stroke:transparent!important;
  //   }
  //   .mat-checkbox-checkmark{
  //     fill: transparent!important;
  //   }
  // }
}

// .paginator .mat-paginator-container{
//   justify-content: flex-start!important;
// }
// seetharam changes
.mat-form-field-subscript-wrapper {
  position: inherit !important;
  box-sizing: content-box !important;
}

.mat-form-field-wrapper {
  padding-bottom: 0px !important;
}

.searchMenu {

  padding: 15px;

  .mat-form-field-wrapper {
    padding-bottom: 1.25em !important;
  }
}

.chipListNtI {
  display: flex;
  align-items: center;

  .mat-form-field-wrapper {
    padding-bottom: 1.25em !important;
  }
}


.datePicker mat-form-field .mat-form-field-wrapper .mat-form-field-flex {
  padding-top: 2px;
}

.selectpoistion {
  margin-left: 3% !important;
  margin-top: 3% !important;
}


.chartcardHeading {

  font-size: 18px;
  font-weight: bold;

}
