@import "ag-grid-community/dist/styles/ag-grid.css";
@import "ag-grid-community/dist/styles/ag-theme-alpine.css";

.form-control input {
  background: #f9f9f9 !important;
}

.form-control ::placeholder {
  color: #aaa9a9 !important;
}

.form-control {
  background: #f9f9f9 !important;
  border: none;
  border-radius: 0.55rem;
}

.container {
  // background-image: url("/assets/images/ScreenTemplate.png");
  padding-top: 25px;
  background-repeat: no-repeat;
  background-position: top;
}

.container.container-big {
  padding-top: 55px;
  // background-image: url("/assets/images/Acknowledgement–1@2x.png");
}

.center-block img {
  margin: auto;
}

.center-block {
  text-align: center;
}

.form-container {
  margin-top: 25px;
}

.center-block h2 {
  margin-top: 20px;
}

.form-container .btn-lg {
  padding-left: 60px;
  padding-right: 60px;
  font-weight: 500;
}

.form-control select:required:invalid {
  color: #aaa9a9 !important;
}

option[value=""][disabled] {
  display: none;
}

.menuOption {
  button {
    padding-right: 23px;
    padding-left: 23px;
    text-align: right;
  }
}

.ag-center-cols-container {
  min-width: 100% !important;

  .ag-cell:focus {
    border-color: transparent !important;
  }
}

// body mat-sidenav.sidebar-panel .mat-nav-list a{
// color: #8c8c8c;
// }
.ackDialog mat-dialog-container {
  overflow: hidden;
}

.headerSpacer {
  flex: 1 1 auto;
}

.mainHeader {
  background-image: url('./assets/images/Group1264@2x.png');
}

.userHeader {
  background-image: url('./assets/images/userBg.png');
}

.userHeaderwhite {
  background-image: url('./assets/images/userBgwhite.png');
}

.ag-center-cols-container {
  min-width: 100% !important;

  .ag-cell:focus {
    border-color: transparent !important;
  }
}

.ag-paging-panel {
  justify-content: flex-start;
}

.tableData {
  padding: 0px 15px 100px 15px;
}

.publish {
  .tableData {
    padding: 0px 15px;
  }
}

.headingSection {
  padding: 20px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.editInput {
  .mat-form-field-flex {
    background: #F6F6F6 0% 0% no-repeat padding-box;
    border: 1px solid #878787;
    border-radius: 6px !important;
  }

  .mat-form-field-underline {
    width: 0 !important;
  }
}

.reportsInput {
  .mat-form-field-flex {
    background: #F6F6F6 0% 0% no-repeat padding-box;
    border: 1px solid #878787;
    border-radius: 6px !important;
    height: 40px;
  }

  .mat-form-field-underline {
    width: 0 !important;
  }
}

.editInput.mat-form-field-invalid {
  .mat-form-field-flex {
    background: #F6F6F6 0% 0% no-repeat padding-box;
    border: 1px solid red !important;
  }
}

.list-content {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  width: 100%;

  .BookdataWrapper {
    width: 66vw;
    padding: 21px 20px 21px 20px;
  }



  .cardLayout {
    border-radius: 13px;
    margin-bottom: 35px;
    padding: 9px 0px 9px 11px;
    display: flex;
    box-shadow: 0px 0px 31px 36px rgb(0 0 0 / 2%), 0px 6px 0px 0px rgb(0 0 0 / 3%), 0px 1px 11px 0px rgb(0 0 0 / 10%);

    .imgSection {
      width: 15vw;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        border-radius: 13px;
      }
    }

    .dataSection {
      overflow: hidden;
      padding-left: 10px;
      width: 73vw;
      display: flex;

      .content {
        width: 100%;

        mat-card-title {
          font-size: 21px;
          margin-top: 8px;
          margin-bottom: 15px;
          font-weight: 500;
          letter-spacing: 0.5px;
        }

        mat-card-subtitle {
          margin-bottom: 8px;
          display: flex;
          font-size: 1em;
          justify-content: flex-start;

          .subtitles {
            font-weight: 100;
            margin-right: 5px;
          }
        }

        .textContent {
          padding-top: 10px;
          padding-right: 20px;
          letter-spacing: 0.5px;
        }
      }
    }

    .addButton .primaryButton {
      margin-top: -9px;
      border-radius: 6px;
      font-size: 21px;
      margin-right: 2px;
      font-weight: 400;
    }

    .closeButton {
      margin-top: -100%;
      margin-right: -14px;
      border: 3px solid #fff;
      box-shadow: none;

      .mat-button-wrapper {
        padding: 0 !important;
      }
    }
  }
}



.userPaginator {
  background-color: inherit;

  .mat-paginator-container {
    justify-content: start;
  }

  .mat-form-field-underline {
    display: none;
  }
}

.ackSection {
  padding: 20px 80px;
}

.acktable {
  width: 100%;

  .mat-header-row {
    background: #004b85;

    .mat-header-cell {
      color: #fff;
    }
  }

  .mat-row:nth-child(even) {
    background-color: #f2f3f8;
  }

  // .mat-row:nth-child(odd){
  //     background-color: inherit;
  // }
}

#cannedPopUp .ag-header-group-cell-label,
.ag-header-cell-label {
  justify-content: center;
}

@media print {
  .hidewhenprint {
    display: none !important;
  }

  * {
    overflow: visible !important;
  }

  td {
    padding-right: 25px !important;
  }
}


@import "./assets/styles/responsive.scss";
